import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

const data = [
    {
        title: 'Website Design',
        subtitle:
            'We are best in terms of digital marketing.',
        icon: '../../../Assets/websiteDesign.png',
    },
    {
        title: 'SEO',
        subtitle:
            'our strategies affect your rankings in search engines to boost your visibility. ',
        icon: '../../../Assets/SEO.png',
    },
    {
        title: 'Social Media',
        subtitle:
            'To increase your reach and engagement on social media platforms we create creative content',
        icon: '../../../Assets/social-media.png',
    }
];

const BrowserSupport = () => {
    return (
        <Box p={2} mb={2}>
            <Box marginBottom={4} textAlign={'center'}>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                    }}
                    gutterBottom
                    color={'secondary'}
                >
                    Services
                </Typography>
                <Typography fontWeight={700} variant={'h4'}>
                    All major digital marketing services
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {data.map((item, i) => (
                    <Grid item xs={12} md={4} key={i}>
                        <Box
                            width={1}
                            height={1}
                            data-aos={'fade-up'}
                            data-aos-delay={i * 100}
                            data-aos-offset={100}
                            data-aos-duration={600}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Box
                                    component={Avatar}
                                    width={80}
                                    height={80}
                                    marginBottom={2}
                                    src={item.icon}
                                />
                                <Typography
                                    variant={'h6'}
                                    gutterBottom
                                    fontWeight={500}
                                    align={'center'}
                                >
                                    {item.title}
                                </Typography>
                                <Typography align={'center'} color="text.secondary">
                                    {item.subtitle}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default BrowserSupport
