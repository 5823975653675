export const PricingData = [
    [
        {title: 'service' ,name: 'Web Design'},
    ],
    [
        {title: 'service' ,name: 'Email Marketing'},
    ],
    [
        {title: 'service' ,name: 'Graphic Design'},
    ],
    [
        {title: 'service' ,name: 'SEO'},
    ]
]